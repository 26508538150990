/* You can add global styles to this file, and also import other style files */
body {
    background: #2C3A45;
    color:  #FFFF;
}
.alerts {
  position: fixed;
  width: 300px;
  right: 30px;
  top: 60px;
  z-index: 9888889909 !important;
}

  .alerts > .alert-dismissable {
      position: relative;
      z-index: 9888889910 !important;
      min-width: 250px;
      opacity: 0.8 !important;
  }

  .alert-Icon {
    color: var(--orange);
 }
 
     .alert-Icon:hover {
         color: #DB3700;
     }